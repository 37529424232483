<template>
  <div class="float-box">
    <div
      v-for="(e, i) in ['one', 'two', 'three']"
      :key="i"
      :class="e"
      style="margin-top: 60px"
    >
      <slot :name="`${e}-line`"></slot>
    </div>
    <div class="waterRipple">
      <div
        v-for="(e, i) in ['one', 'two', 'three']"
        :key="`circle${i}`"
        class="circle"
      >
        <span
          :class="['circle__el', `circle__el_${e}`]"
          :style="{ animationDelay: `${i * 0.5}s` }"
        ></span>
      </div>
    </div>
    <img
      v-for="e in 7"
      :key="`light${e}`"
      class="light"
      src="@/assets/screen/bj/light.svg"
      width="5"
      height="100"
      alt="light"
      :style="{
        marginLeft: `${100 * e + 1}px`,
        animationDelay: `${Math.random() * 3}s`,
      }"
    />
  </div>
</template>

<script>
export default {
  //组件注册
  components: {},
  //组件传值
  props: {},
  data() {
    return {};
  },
  //计算属性
  computed: {},
  //属性监听
  watch: {},
  //DOM访问
  mounted() {},
  //保存方法
  methods: {},
};
</script>

<style lang="scss" scoped>
$circleColor: rgba(43, 193, 255, 0.48);
$sizeto: 550px;
.float-box {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 120px;
  z-index: 1;
  overflow: hidden;
  .one {
    height: 62px;
    display: flex;
    justify-content: center;
  }
  .two {
    height: 62px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .three {
    height: 62px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .line-box {
    z-index: 10;
    min-width: 154px;
    height: 62px;
    white-space: nowrap;
    padding: 0 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #009cff;
    box-shadow: rgba(0, 150, 255, 0.37) 0px 0px 20px 10px;
    background: linear-gradient(
      4deg,
      rgba(0, 150, 255, 0.37),
      rgba(0, 150, 255, 0)
    );
    border-radius: 5px;
    //animation: bounce-down 2.5s linear infinite;
    span {
      font-size: 15px;
      color: #def8ff;
    }
    .cyan-value,
    .yellow-value,
    .red-value {
      letter-spacing: 2px;
      font-size: 25px;
      font-weight: bold;
    }
    .cyan-value {
      color: #05fffc;
    }
    .yellow-value {
      color: #ffcc1b;
    }
    .red-value {
      color: #fe1329;
    }
    .separate-value {
      margin: 0 6px;
    }
  }
  .waterRipple {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    .circle {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      transform-origin: center center;
      transform: perspective(400px) rotatex(80deg);
      .circle__el {
        width: 0;
        height: 0;
        background: transparent;
        border: 5px solid $circleColor;
        border-radius: 50%;
        animation: go 1.5s ease-out infinite;
      }
    }
  }
  .light {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    opacity: 0;
    animation: upward 5s linear infinite;
  }
}
@keyframes bounce-down {
  25% {
    transform: translateY(-10px);
  }
  50%,
  100% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(10px);
  }
}
@keyframes go {
  100% {
    width: $sizeto;
    height: $sizeto;
    box-shadow: 0 0 15px $circleColor;
    opacity: 0;
  }
}
@keyframes upward {
  0%,
  40% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  60%,
  100% {
    transform: translateY(-650px);
    opacity: 0;
  }
}
</style>
